export const consumerGrowthQuery = `// groq
*[_type == 'consumerGrowth'][0] {
  seo {
    title,
    keywords,
    description
  },
  firstSection {
    title,
    cateogry
  },
  secondSection {
    title,
    "_rawDescription": description,
    mainImage {
      asset-> {
        url
      }
    }
  },
  thirdSection {
    sectionItem[] {
      title,
      _rawDescription
    },
    mainImage {
      asset-> {
        url
      }
    },
    title
  },
  fourthSection {
    quotation,
    provider
  }
}
`;
