import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  SEO,
  TransactionalFirstSection,
  IndustrySubMenu,
  IndustrySubSecondSection,
  IndustrySubThirdSection1,
  HomeCareer,
} from '../components';
import { SEO_TEXT } from '../constants';
import { SanityConsumerType } from '../utils/globalTypes';
import { getClient } from '../utils/sanity.client';
import { consumerGrowthQuery } from '../utils/querys/consumerGrowth/query';

interface Props {
  data: {
    sanityConsumerGrowth: SanityConsumerType;
  };
}

const ConsumerGrowth = ({ data: { sanityConsumerGrowth } }: Props) => {
  const [consumerData, setConsumerData] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(consumerGrowthQuery);
      setConsumerData(data);
    };
    if (consumerData === undefined && url.search === '?preview_mode=true') getData();
  }, [consumerData]);
  return (
    <Fragment>
      <SEO title={sanityConsumerGrowth.seo?.title || ''} description={sanityConsumerGrowth.seo?.description || ''} />
      <TransactionalFirstSection
        content={consumerData ? consumerData.firstSection : sanityConsumerGrowth.firstSection}
      />
      <IndustrySubMenu activeIndex={0} />
      <IndustrySubSecondSection
        content={consumerData ? consumerData.secondSection : sanityConsumerGrowth.secondSection}
      />
      <IndustrySubThirdSection1
        content={consumerData ? consumerData.thirdSection : sanityConsumerGrowth.thirdSection}
      />
      <HomeCareer content={consumerData ? consumerData.fourthSection : sanityConsumerGrowth.fourthSection} />
    </Fragment>
  );
};

export default ConsumerGrowth;
export const pageQuery = graphql`
  query {
    sanityConsumerGrowth {
      seo {
        title
        keywords
        description
      }
      firstSection {
        title
        cateogry
      }
      secondSection {
        title
        _rawDescription
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      thirdSection {
        sectionItem {
          title
          _rawDescription
        }
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
        title
      }
      fourthSection {
        quotation
        provider
      }
    }
  }
`;
